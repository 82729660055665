<template lang="pug">
.card-content
    .box-charts
        template(v-if="status_detail_map_data === 'loading'")
            Loading(show="true")

        template(v-else-if="detail_map_data && detail_map_data.target_charts && detail_map_data.target_charts.rest_time")
            highcharts(
                :options="chartOptions"
            )

        NoDataAvailable(v-else)
</template>

<script>
import Loading from '@/components/Loading.vue';
import NoDataAvailable from '@/components/NoDataAvailable.vue';
import { mapState } from 'vuex';

export default {
    name: 'resttime',
    components: {
        Loading,
        NoDataAvailable,
    },    
    data() {
        return {
            chartLoaded: false,
            chartOptions: {
                chart: {
                    type: 'bar'
                },
                title: {
                    text: null
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        step: 1
                    }
                },
                yAxis: {
                    tickInterval: 1,
                    title: {
                        text: null
                    },
                },
                series: [ {
                    }
                ],
                legend:{ enabled:false },
                credits: {
                    enabled: false
                },
            },
            dataCat: null,
            dataSeries: null,
            filterNull: false,
        }
    },
    computed: {
        ...mapState('territoryVirtual', [
            'status_detail_map_data',
            'detail_map_data',
        ]),
    },   
    watch: {
        detail_map_data() {
            this.startChart();
        },
        chartLoaded() {},
    },     
    methods: {
        startChart() {
            if (this.detail_map_data.target && this.detail_map_data.target.length > 0) {

                this.chartOptions.xAxis.categories = this.detail_map_data?.target_charts?.rest_time?.categories || null

                this.chartOptions.series[0].data = this.detail_map_data?.target_charts?.rest_time?.data || null

                this.chartLoaded = true;
            }
        },
    },
    mounted(){

        this.startChart();
    }
}
</script>