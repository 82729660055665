<template lang="pug">
.box__body
    .card.card--table.card--no-bg
        .card__header.card--table__header
            h2.text-xl {{ detail_map_data.name ? detail_map_data.name : (name?name:'-') }}
            .header-cta
                .flex.items-center
                    .block
                        router-link.btn-default(to="/global-territory-virtual")
                            IconSet(type="chevron-left")
                            span.m-hide.ml-3 Back

        .card__body.p-4.pt-0
            .flex.items-center.mb-2.justify-between

                .flex.items-center.flex-grow.gap-3

                    .field
                        .relative.field-style.w-full
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate" 
                                ref="picker"
                                v-model="dateRange"
                                @update="pickerUpdate($event)" 
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                                :date-format="dateFormat" 
                                opens="right"
                                :ranges="default_ranges"
                                autoApply=true
                            )
                                div(
                                    slot="input" 
                                    slot-scope="picker") 
                                        | {{ picker.startDate | date}} - {{ picker.endDate  | date}}

                            .field-icon
                                IconSet(type="calendar")

                    button.btn-primary-o(@click="toggleAdvanceFilter()") Advance Datetime Filter
                    
                    //- default-nya All Target
                    
                    .field.w-48
                        select.field-style(v-model="optionFilter")
                            option(value="last") Latest Location
                            option(value="list") Multiple Location
                            option(value="same_day") Same Day
                            
                        .field-icon
                            IconSet(
                                type="chevron-down"
                            )   

                    button.btn-primary-o(@click="toggleFilterTarget()") Target Filter
         

                    button.btn-primary(
                        :disabled="isLoading"
                        :class="{'btn-disabled':isLoading}"
                        @click="filterList(detail_map_data.target)"
                    ) Filter

                .block(v-if="(detail_map_data.target && detail_map_data.target.length > 0)")
                    .flex.flex-wrap.items-center.justify-between
                        .field.mr-3.relative
                        JsonCSV(
                            :data="csvData"
                            :name="csvName"
                            class="btn btn-primary"
                        ) Download XLS


            //-===== summary filter
            .flex.flex-wrap.items-center.p-4.border.border-black.mb-2.gap-4.bg-black
                //- .flex.flex-wrap.gap-2.items-center
                p.text-sm Filter date 
                template(v-if="Object.keys(selectedTimeByDate).length > 0")
                    span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}                        
                    //- span.bg-gray-800.text-xs.p-1.block 1 Jan 2022 00:00 - 24:00
                    //- span.bg-gray-800.text-xs.p-1.block 6 Jan 2022 00:00 - 24:00
                    //- span.bg-gray-800.text-xs.p-1.block 7 Jan 2022 10:00 - 11:00, 11:00 - 12:00, 12:00 - 13:00, 13:00 - 14:00
                    
                template(v-if="pickedTarget.length > 0")
                    .flex.flex-wrap.gap-2.items-center
                        p.text-sm Target
                        template(v-for="index in pickedTarget")
                            span.bg-gray-800.text-xs.p-1.block {{getTargetName(index)}}

            .card.card--tab.col-span-2
                .card--tab__body
                    .box-tabs
                        vue-tabs(@tab-change="handleTabChange")
                            v-tab(title="Target Locations")
                                TargetLocation(:detail-data="detailData")

                            v-tab(title="Targets Possibility Meet")
                                TargetPossibilityMeet(:detail-data="detailData")
                                //- .info-target-area(:class="{'info-target-area--column' : landscapeMap === true}")
                                //-     .info-target-area__map
                                //-         .box-map-territory(:class="{'box-map-territory--full-screen' : mapFullScreen === true}")
                                //-             .map-settings
                                //-                 //- template(v-if="vendorMap != 'gmap'")
                                //-                 button(@click="toggleFullScreen()")
                                //-                     template(v-if="!mapFullScreen")
                                //-                         IconSet(type="maximize")
                                //-                     template(v-else)
                                //-                         IconSet(type="minimize")

                                //-                 button(@click="toggleLandscape()" v-if="!mapFullScreen")
                                //-                     IconSet(type="landspace")

                                //-             .block.box-map.flex.items-center.justify-center(v-if="isLoading")
                                //-                 Loading(show="true")

                                //-             .box-map-territory__frame(v-else)
                                //-                 template(v-if="detail_map_data.point || detail_map_data.target_list")
                                //-                     l-map#mapTeritory(v-if="vendorMap==='osm'"  ref="mapRefOSM" :zoom="10" :center="center" :options="options" style="height: 100%;width: 100%;")
                                //-                         l-tile-layer(:url="url" :attribution="attribution")
                                //-                         //- l-marker(v-for="(location, index) in locations.territory" :lat-lng="location.position" v-bind:key="index" :ref="'marker_'+location.index" :icon="getMarkerOsmIcon(location)")
                                //-                             l-popup(:content="location.name")
                                //-                         l-marker(v-if="locations.length > 0" v-for="(location, index) in locations" :lat-lng="location.position" :key="index" :ref="'marker_'+location.index" :icon="getMarkerOsmIcon(location)")
                                //-                             l-popup(:content="location.name")
                                //-                         template(v-if="Object.keys(locations_cluster).length > 0")
                                //-                             l-marker-cluster(v-for="(location, indexl) in locations_cluster" :options="cluster_opt" :key="indexl" :ref="'marker_cluster_'+indexl")
                                //-                                 l-marker(v-for="(loc, index) in location" :lat-lng="loc.position" :key="index" :ref="'marker_'+loc.index" :icon="getMarkerOsmIcon(loc)")
                                //-                                     l-popup(:content="loc.name")

                                //-                         //- l-rectangle(:bounds="rectangleOSM.bounds" :l-style="rectangleOSM.style")
                                //-                         l-rectangle(:bounds="rectangleOSM.bounds" :color="rectangleOSM.style.color" :weight="rectangleOSM.style.weight" :opacity="rectangleOSM.style.opacity" :fillColor="rectangleOSM.style.fillColor" :fillOpacity="rectangleOSM.style.fillOpacity")

                                //-                     gmap-map(v-else ref="mapRefDetail" :center="center" :zoom="11" :options="options" style="width:100%; height: 100%;")
                                //-                 NoDataAvailable(v-else)

                                //-     .w-full(v-if="isLoading")
                                //-         Loading(show="true") 

                                //-     .info-target-area__table.pr-8(v-else)
                                //-         .box-table(v-if="detail_map_data && detail_map_data.target")
                                //-             <v-client-table ref='myTable' :data="detail_map_data.target_list" :columns="columnsTable" :options="optionsTable">
                                //-                 span(slot="target" slot-scope="{ row, index }")
                                //-                     .flex.items-center.gap-3
                                //-                         .cursor-pointer.link.text-sm(@click="showPopup((index-1), row.location[0])")
                                //-                             .flex.flex-col
                                //-                                 span {{ row.target_name ? row.target_name : '-' }}
                                //-                 span(slot="label" slot-scope="props").col-target-label
                                //-                     .target-label(:style="'background-color: '+ props.row.label +';'")
                                //-                 span(slot="datetime" slot-scope="props") 
                                //-                     .text-xs {{ props.row.created_at ? props.row.created_at.substring(0, 20).replace('T',' ') : '-' }}
                                //-                 span(slot="msisdn" slot-scope="props") 
                                //-                     .text-xs {{ props.row.msisdn ? props.row.msisdn.toString() : '-' }}
                                //-                 span(slot="lac" slot-scope="props") 
                                //-                     .text-xs {{ props.row.lac ? props.row.lac : '-' }}                                     
                                //-                 span(slot="ci" slot-scope="props") 
                                //-                     .text-xs {{ props.row.ci ? props.row.ci : '-' }}                                      
                                //-                 span(slot="rat" slot-scope="props") 
                                //-                     .text-xs {{ props.row.rat ? props.row.rat : '-' }}
                                //-             </v-client-table>                             
   
    .grid.grid-cols-3.gap-4.p-4
        .card.card--tab.col-span-2
            .card--tab__header
                h2.text-xl Most visited location
            .card--tab__body
                .box-tabs
                    vue-tabs
                        v-tab(title="Weekdays")
                            Weekdays 
                        
                        v-tab(title="Weekend")
                            Weekend
                        
                        v-tab(title="Rest Time")
                            RestTime

                        v-tab(title="Working Hours")
                            WorkingHours
        
        .card
            .card__header
                h2.text-xl Most Found Target
            .card__body
                Target

    //-=== advance filter template ====-//
    .section-advance-filter(v-if="openAdvanceFilter")
        .section-advance-filter__header
            .flex-grow.flex.flex-col.gap-2
                .flex.items-center.gap-2
                    .flex.text-sm Showing Data from {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}
                    //- button.btn-primary-o.btn-small(@click="toggleTimeFilter('all')") Filter Time

                .text-xs Please select specific date time bellow:
                .flex.items-center.gap-2
                    p.text-xs Selected date
                    .flex.flex-wrap.gap-2.items-center.font-bold
                        template(v-if="Object.keys(selectedTimeByDate).length > 0")
                            span(v-for="(value, key) in selectedTimeByDate" :key="key").bg-gray-800.text-xs.p-1.block {{dateFormatGlob(key,'DD MMMM YYYY')}} {{toString(value,null)}}
                        span(v-else).bg-gray-800.text-xs.p-1.block {{dateFormatGlob(from_date,'DD MMMM YYYY')}} - {{dateFormatGlob(to_date,'DD MMMM YYYY')}}               
            
            .flex.items-center.gap-2
                button.btn-default(@click="toggleAdvanceFilter('reset')") Reset
                button.btn-primary(@click="toggleAdvanceFilter()") Save Filter
        
        .section-advance-filter__body
            .box-month(v-for="month,idx in months" :key="idx")
                .block.mb-2 {{month.name_long}} {{month.year}}
                .grid.grid-cols-10.gap-2
                    button.btn-item(
                        v-for="i in month.days" :key="i" 
                        v-if="i>=month.start_day && i <= month.end_day"
                        @click="toggleTimeFilter(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))" 
                        :class="checkDateStyle(month.year+'-'+month.month+'-'+String(i).padStart(2, '0'))"
                    )
                        span {{i}} {{month.name_short}}
                        span.text-xs {{ getTimeDesc(month.year+'-'+month.month+'-'+String(i).padStart(2, '0')) }}
                    
        //- select date time
        .modal-date-time(v-if="FilterTime")
            .section-date-time
                .section-date-time__header
                    h2.text-white.text-xl {{ filterTimeBy == 'all'? (dateFormatGlob(from_date,'DD MMMM YYYY')+' - '+ dateFormatGlob(to_date,'DD MMMM YYYY')): dateFormatGlob(filterTimeBy,'DD MMMM YYYY')}}
                    button.btn-icon-40(@click="toggleTimeFilter()")
                        IconSet(type="close")
                .section-date-time__body
                    button.btn-item(
                        v-for="time in timeOpt"
                        @click="selectTime(time.value)"
                        :class="checkTimeStyle(time.value)"
                    ) {{time.label}}
                .section-date-time__footer
                    button.btn-primary-o(@click="selectTime('all')") Reset to 00:00 - 24:00
                    button.btn-primary(@click="toggleTimeFilter('apply')") Apply


    .popup-layer.is-show(v-if="openFilterTarget")
        .popup-container
            .popup-container__header
                h2.text-xl Target Found in {{ detail_map_data.name ? detail_map_data.name : '-' }}
                button.btn-icon-40(@click="toggleFilterTarget()")
                    IconSet(type="close")
            .popup-container__body
                p.mb-4 Please select any spesific targets
                template(v-if="detail_map_data.target && detail_map_data.target.length > 0")
                    ul.flex.flex-col.gap-2
                        li(v-for="data, index in detail_map_data.target")
                            button.btn-item.w-full(:class="{'btn-item--selected': btnSelectedTarget.includes(index)}" @click="selectTarget(data.msisdn.toString(), index)") {{ data.target_name }}

            .popup-container__footer
                button.btn-primary-o(@click="resetTarget()") Reset to select All Targets
                button.btn-primary(@click="toggleFilterTarget()") Apply
</template>

<script>
import { decrypt } from '@/util/crypto';
import IconSet from '@/components/IconSet.vue'
import moment from 'moment'
import Spinner from '@/components/Spinner.vue';
import { env } from '@/config/env';
import { gmapApi } from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {LMap, LTileLayer, LMarker, LPopup, LPolyline, LRectangle} from 'vue2-leaflet';
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import NoDataAvailable from '@/components/NoDataAvailable.vue';

import Weekdays from './top-info/Weekdays.vue'
import Weekend from './top-info/Weekend.vue'
import RestTime from './top-info/RestTime.vue'
import WorkingHours from './top-info/WorkingHours.vue'
import Target from './top-info/Target.vue'
import TargetLocation from './components/TargetLocation.vue';
import TargetPossibilityMeet from './components/TargetPossibilityMeet.vue';

import JsonCSV from 'vue-json-csv'
import { VueTabs,VTab } from 'vue-nav-tabs'
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.imagePath = ''
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
    name: 'territory-virtual-detail',
    components: {
        IconSet,
        DateRangePicker,
        Spinner,
        VueAdsPagination,
        VueAdsPageButton,
        JsonCSV,        
        LMap,
        LTileLayer,
        Loading,
        LMarker,
        LPopup,
        LPolyline,
        LRectangle,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        NoDataAvailable,
        VueTabs,
        VTab,
        Weekdays,
        Weekend,
        RestTime,
        WorkingHours,
        Target,
        GmapCluster,
        TargetLocation,
        TargetPossibilityMeet,
    },

    data: function() {
        return {
            openPopUp: false,
            popUpWatchlist: false,
            landscapeMap: false,
            mapFullScreen: false,
            openAdvanceFilter: false,
            FilterTime: false,
            openFilterTarget: false,
            selectedTarget: false,

            options: {
                gestureHandling: 'cooperative',
                fullscreenControl : false,
                styles: [
                    {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
                    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
                    {
                      featureType: 'administrative.locality',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'geometry',
                      stylers: [{color: '#263c3f'}]
                    },
                    {
                      featureType: 'poi.park',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#6b9a76'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry',
                      stylers: [{color: '#38414e'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#212a37'}]
                    },
                    {
                      featureType: 'road',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#9ca5b3'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry',
                      stylers: [{color: '#746855'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'geometry.stroke',
                      stylers: [{color: '#1f2835'}]
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#f3d19c'}]
                    },
                    {
                      featureType: 'transit',
                      elementType: 'geometry',
                      stylers: [{color: '#2f3948'}]
                    },
                    {
                      featureType: 'transit.station',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#d59563'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'geometry',
                      stylers: [{color: '#17263c'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.fill',
                      stylers: [{color: '#515c6d'}]
                    },
                    {
                      featureType: 'water',
                      elementType: 'labels.text.stroke',
                      stylers: [{color: '#17263c'}]
                    }
                ],
            },
            timeOpt:[
                {
                    label:'00:00 - 01:00',
                    value:'00:00-01:00'
                },
                {
                    label:'01:00 - 02:00',
                    value:'01:00-02:00'
                },
                {
                    label:'02:00 - 03:00',
                    value:'02:00-03:00'
                },
                {
                    label:'03:00 - 04:00',
                    value:'03:00-04:00'
                },
                {
                    label:'04:00 - 05:00',
                    value:'04:00-05:00'
                },
                {
                    label:'05:00 - 06:00',
                    value:'05:00-06:00'
                },
                {
                    label:'06:00 - 07:00',
                    value:'06:00-07:00'
                },
                {
                    label:'07:00 - 08:00',
                    value:'07:00-08:00'
                },
                {
                    label:'08:00 - 09:00',
                    value:'08:00-09:00'
                },
                {
                    label:'09:00 - 10:00',
                    value:'09:00-10:00'
                },
                {
                    label:'10:00 - 11:00',
                    value:'10:00-11:00'
                },
                {
                    label:'11:00 - 12:00',
                    value:'11:00-12:00'
                },
                {
                    label:'12:00 - 13:00',
                    value:'12:00-13:00'
                },
                {
                    label:'13:00 - 14:00',
                    value:'13:00-14:00'
                },
                {
                    label:'14:00 - 15:00',
                    value:'14:00-15:00'
                },
                {
                    label:'15:00 - 16:00',
                    value:'15:00-16:00'
                },
                {
                    label:'16:00 - 17:00',
                    value:'16:00-17:00'
                },
                {
                    label:'17:00 - 18:00',
                    value:'17:00-18:00'
                },
                {
                    label:'18:00 - 19:00',
                    value:'18:00-19:00'
                },
                {
                    label:'19:00 - 20:00',
                    value:'19:00-20:00'
                },
                {
                    label:'20:00 - 21:00',
                    value:'20:00-21:00'
                },
                {
                    label:'21:00 - 22:00',
                    value:'21:00-22:00'
                },
                {
                    label:'22:00 - 23:00',
                    value:'22:00-23:00'
                },
                {
                    label:'23:00 - 24:00',
                    value:'23:00-23:59'
                },
            ],

            infoWindows: [],
            mapLoaded: false,
            rectLoaded: false,
            center: {},
            markers: [],
            // places: [],
            // heatmap: null,
            mapsLatLng: [],
            polyline: null,

            targetValue: '',
            optionFilter: 'last',
            dataTarget: [],
            pickedTarget: [],
            btnSelectedTarget: [],
            btnSelectedMonth: [],

            filterTimeBy: '',
            tmpSelectedTime:[],
            selectedTimeByDate:{},
            selectedTimeAll:[],

            rectangle_data: {},
            rectangle_bounds: [],      
            
            // default rectangle bounds -> monas, jakarta
            bounds: {
                north: -6.170255000000001,
                east: 106.83286432275388,
                south: -6.18188099999998,
                west: 106.8211273227539,
            },

            imgAvatar: this.$store.getters['config/getDefaultImageProfile'],

            name: '',
            point: '',
            description: '',
            case_name: '',
            target_selected: {},
            msisdn_selected: '',
            from_date: '',
            to_date: '',

            months: [],

            detailData: false,
            clickTarget: false,
            optTarget: [],

            dateRange: {
                startDate: '',
                endDate: '',
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            csvName: 'territory_virtual_list'+moment().format('YYYY-MM-DD_HH:mm:ss')+'.xls',
            csvData: [],         

            marker_color: {
                green: '#48bb78',
                red: '#f56565',
                purple: '#9f7aea',
                orange: '#ed8936',
                blue: '#4299e1',
            },            

            columnsTable: ['target','label','datetime','msisdn','lac','ci','rat'],
            optionsTable: {
                headings: {
                    'target': 'Target',
                    'label': '',
                    'datetime': 'Datetime',
                    'msisdn': 'MSISDN',
                    'lac': 'LAC/TAC',
                    'ci': 'CI',
                    'rat': 'RAT',
                },
                filterable: false,
                sortable: [],
                // perPage: 10,
                pagination: { 
                    chunk: 5 
                    // dropdown: false 
                },
                // perPageValues: [10,25,50,100],
                skin: 'table table-overflow',
                // skin: 'table-static w-full p-4',
                sortIcon: { 
                    base:'iconfont',
                    is:'iconsort',
                    up: 'iconup',
                    down: 'icondown'
                },
                columnsClasses: {
                    'datetime': 'font-mini',
                },
                // orderBy:{column:false}
            },
            tableThead: [
                {
                    name: 'Target',
                },
                {
                    name: '',
                },
                {
                    name: 'Datetime',
                },
                {
                    name: 'MSISDN',
                },
                {
                    name: 'LAC/TAC',
                },
                {
                    name: 'CI',
                },
                {
                    name: 'RAT',
                },                
            ],    
            
            total_data: 0,
            items_per_page: 1000,

            size: [10, 25, 50, 100],

            page: 0,
            start: 0,
            end: 0,
            
            vendorMap: (typeof env.VUE_APP_VENDOR_MAP!==undefined || typeof env.VUE_APP_VENDOR_MAP!=='undefined'? env.VUE_APP_VENDOR_MAP: 'gmap'),
            // vendorMap: 'gmap',
            
            // openmapstreet
            // url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
            url: `${env.VUE_APP_OSM_CDN_URL}{z}/{x}/{y}.png`,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            // locations: {
            //     territory:[],
            //     history:[]
            // },
            locations: [],
            locations_cluster: [],
            cluster_opt: {
                // spiderfyOnMaxZoom: true,
                // showCoverageOnHover: true,
                // zoomToBoundsOnClick: true
            },
            polylineOSM: {
                latlngs:[],
                color:'#FF0000'
            },
            newLoc: '',
            newLt : 0, 
            newLng : 0,
            icon: L.icon({iconUrl: "null",}),
            rectangleOSM: {
                bounds: [[-6.18188099999998, 106.8211273227539], [-6.170255000000001, 106.83286432275388]],
                style: {
                            color: "#FF0000",
                            opacity: 0.8,
                            weight: 3,
                            fillColor: "#FFFFFF",
                            fillOpacity: 0.35,
                        }
            },

            currentClickMarker: null,
            spideredMarker: [],

            gInfoWIndow: null,

        }
    },

    computed: {
        google: gmapApi,
        ...mapState('territoryVirtual', [
            'status_detail_map_data',
            'detail_map_data',
        ]),

        route_name() {
            return this.$route.name;
        },
        id() {
            return this.$route.params.id;
        },
        rectangle: {
            get() {
                // console.log(Object.keys(this.rectangle_data).length);
                if (!this.rectangle_data)
                    return this.google.maps.Rectangle;

                return this.rectangle_data;
            },
            set(v) {
                this.rectangle_data = v;
            },
        },
        isLoading(){
            if(this.status_detail_map_data === 'loading') {
                return true
            }
            return false

        },        
    },

    watch: {
        google() {},
        route_name() {
            // this.getData();
        },
        id() {
            this.getData();
        },
        rectangle() {},
        detail_map_data(newData) {
            if (Object.keys(newData).length > 0) {
                this.total_data = parseInt(newData.total_data);

            } else {
                this.total_data = 0;
            }
            if ((newData.target && newData.target.length > 0) || (newData.point && newData.point[0])){
                this.name = (newData.name ? newData.name : '');
                this.description = (newData.description ? newData.description : '');
                this.case_name = (newData.case_name ? newData.case_name : '');
                if (newData.point && newData.point[0]) {
                // console.log(newData.point[0])

                    let bounds = {
                        north: parseFloat(newData.point[0].north_east.lat),
                        east: parseFloat(newData.point[0].north_east.lng),
                        south: parseFloat(newData.point[0].south_west.lat),
                        west: parseFloat(newData.point[0].south_west.lng),
                    };

                    let point = {
                        "north_east": {
                            "lat": bounds.north,
                            "lng": bounds.east
                        },
                        "south_west": {
                            "lat": bounds.south,
                            "lng": bounds.west
                        }
                    };

                    if (this.rectangle_bounds.length === 0) {
                        this.rectangle_bounds.push(point);
                    } else {
                        this.rectangle_bounds[0] = point;
                    }

                    this.point = JSON.stringify(this.rectangle_bounds);
                }
                // this.startMap();
                this.setData(newData);
                this.getDataCsv(newData.target_list);
            }
        },

        msisdn_selected() {},

        total_data() {
            if (isNaN(this.total_data)) {
                this.total_data = 0
            }
        },
        items_per_page() {
        },
        page() {
            this.getData();
        },
        most_found_target(){

        },
        currentClickMarker(newData){
            if (!newData)
                return
            const google = this.google; 
            const map = this.$refs.mapRef
            const infoWindow = this.gInfoWIndow; 
            // console.log('watch currentClickMarker')
            google.maps.event.trigger(newData, 'click');

            infoWindow.close()
            infoWindow.setContent(newData.desc);
            infoWindow.open(map, newData);
            // console.log('currentClickMarker null')
            // this.currentClickMarker=null

        },
    },
    
    methods: {
        showPopup(index, coor) {
            if (this.vendorMap == 'osm') {
                try {
                    const marker = this.$refs['marker_'+index][0].mapObject 
                    const map = this.$refs.mapRefOSM.mapObject
                    // console.log('map')
                    // console.log(map)
                    // if (this.bounds.length){
                    //     map.fitBounds(L.latLngBounds([this.bounds]));
                    // }
                    map.fitBounds(L.latLngBounds([[coor.lat, coor.lng]]));
                    // console.log(marker)
                    marker.openPopup()
                    console.log('showPopUp marker')
                } catch (error) {
                    const marker = this.$refs['marker_'+index+"_cluster"][0].mapObject 
                    // console.log('marker in cluster')
                    // console.log(marker)
                    const cluster = this.$refs['marker_cluster_'+coor.lat+','+coor.lng]
                    // console.log('cluster')
                    // console.log(cluster)
                    for (let index = 0; index < cluster.length; index++) {
                        const elem = cluster[index].mapObject
                        // console.log('elem')
                        // console.log(elem)
                        elem.zoomToShowLayer(marker, function () {
                            marker.openPopup();
                        });
                        // marker.openPopup();
                        console.log('showPopUp cluster')
                    }
                }
            } else {
                const google = this.google;  
                const map = this.$refs.mapRefDetail
                const markerLatLng = this.markers[index].getPosition()
                // console.log(map)
                map.panTo(markerLatLng);
                try {
                    const mapObj = map.$mapObject
                    // console.log('mapObj', mapObj)
                    const mapZoom = mapObj.zoom
                    // console.log('mapZoom', mapZoom)
                    if (mapZoom < 17) {                        
                        var bounds = new google.maps.LatLngBounds();
                        bounds.extend(markerLatLng)
                        map.fitBounds(bounds); 
                    }
                    // console.log('sukses map.fitBounds(bounds)')
                } catch (error) {
                    // console.log('error map.fitBounds(bounds)')
                    console.log(error)
                }

                // google.maps.event.trigger(this.markers[index], 'click');
                this.currentClickMarker = this.markers[index]
            }
        },
        getTargetName(msisdn) {
            var target = this.optTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].name
            } else {
                return '-'
            }
        }, 
        getTargetId(msisdn) {
            var target = this.optTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].id
            } else {
                return '-'
            }
        }, 
        getTargetLabel(msisdn) {
            var target = this.optTarget.filter( val => val.msisdn == msisdn )
            if (target.length > 0) {
                return target[0].label
            } else {
                return 'white'
            }
        },   

        toggleTimeFilter(data) {
            // console.log('=====toggleTimeFilter: data')
            // console.log(data)
            // if(!this.btnSelectedMonth.includes(index)){
            //     this.btnSelectedMonth.push(index);
            // } else {
            //     this.btnSelectedMonth.splice(this.btnSelectedMonth.indexOf(index), 1);
            // } 
            this.FilterTime = !this.FilterTime
            if (this.FilterTime) {
                this.filterTimeBy = data
                if (data == 'all') {
                    this.tmpSelectedTime=this.selectedTimeAll;
                } else {
                    try {
                        console.log('===data:',data)
                        this.tmpSelectedTime = this.selectedTimeByDate[data] || []
                    } catch (error) {
                        console.log('error get ByDate')
                        console.log(error)
                        this.tmpSelectedTime = []
                    }
                }
            } else {
                if (data=='apply') {
                    console.log('=====toggleTimeFilter apply: this.filterTimeBy')
                    console.log(this.filterTimeBy)
                    if (this.filterTimeBy == 'all') {
                        this.selectedTimeAll = this.tmpSelectedTime;
                        this.selectedTimeByDate = {};
                        if (this.tmpSelectedTime.length > 0) {
                            for (let i = 0; i < this.months.length; i++) {
                                // const element = this.months[i];
                                for (let j = this.months[i]['start_day']; j <= this.months[i]['end_day']; j++) {                   
                                    // example format '2022-12-31'
                                    const key =  String(this.months[i]['year'])+'-'+ String(this.months[i]['month'])+'-'+ String(j).padStart(2, '0') 
                                    this.selectedTimeByDate[key] = this.tmpSelectedTime;
                                }
                            }
                        }
                    } else {
                        if (this.tmpSelectedTime.length == 0) {
                            delete this.selectedTimeByDate[this.filterTimeBy]; 
                        } else {
                            this.selectedTimeByDate[this.filterTimeBy] = this.tmpSelectedTime;
                        }
                    }
                }
                this.filterTimeBy = '';
                this.tmpSelectedTime=[];
            }
        },       

        selectTime(value){
            if (value == 'all') {                
                this.tmpSelectedTime=[];
                for (let index = 0; index < this.timeOpt.length; index++) {
                    const element = this.timeOpt[index].value;
                    this.tmpSelectedTime.push(element);
                }
            } else {
                if(!this.tmpSelectedTime.includes(value)){
                    this.tmpSelectedTime.push(value);
                }else{
                    this.tmpSelectedTime.splice(this.tmpSelectedTime.indexOf(value), 1);  //deleting
                }            
            }
        },
        checkTimeStyle(value){
            let btnDisabled = false
            let btnSelected = false
            try {                
                // console.log('checkTimeStyle value')
                // console.log(value)
                // console.log('checkTimeStyle this.tmpSelectedTime')
                // console.log(this.tmpSelectedTime)
                if(this.tmpSelectedTime.includes(value)){
                    btnSelected = true
                }
                if (this.filterTimeBy != 'all' && this.selectedTimeAll.length !=0) {
                    if(!this.selectedTimeAll.includes(value)){
                        btnDisabled = true
                    }
                }
            } catch (error) {
                // console.log('error checkTimeStyle')
                // console.log(error)
            }
            return {
                'btn-disabled': btnDisabled,  
                'btn-item--selected': btnSelected,  
            }
        },
        checkDateStyle(value){
            let btnSelected = false
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length) {
                    btnSelected = true
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return { 
                'btn-item--selected': btnSelected,  
            }
        },   
        getTimeDesc(value){
            let desc = '00:00 - 24:00'
            try {
                let check = this.selectedTimeByDate[value]
                if (check.length > 1) {
                    desc = 'Multiple times'
                } else {
                    desc = check.toString()
                }
            } catch (error) {
                // console.log('error checkDateStyle')
                // console.log(error)
            }
            return desc
        },             
        
        getMonthInfo() { 
            this.selectedTimeAll = []
            this.selectedTimeByDate = {}            
            this.months = []
            var from = this.from_date.split(/-| /)
            var to = this.to_date.split(/-| /)

            var year = from[0]
            var month = from[1]
            while ((month <= parseInt(to[1]) && year == parseInt(to[0])) || (month != parseInt(to[1])+1 && year < parseInt(to[0]))) {
                const date = new Date();
                date.setMonth(month - 1);
                var getMonthLong = date.toLocaleString('default', { month: 'long' });
                var getMonthShort = date.toLocaleString('default', { month: 'short' });

                var getDays = new Date(year,month,0).getDate();

                this.months.push({
                    name_long: getMonthLong,
                    name_short: getMonthShort,
                    days: getDays,
                    year: year,
                    month: month,
                    start_day: (month == from[1] && year == from[0])? parseInt(from[2]):1,
                    end_day: (month == to[1] && year == to[0])? parseInt(to[2]):getDays,
                });
                
                if (month == 12){
                    month = 0
                    year++
                }
                month++
            }
        },

        changeImgAvatar(e) {
            const file = e.target.files[0];
            this.ImgAvatar = URL.createObjectURL(file);
        },
        getDefaultImage(e) {
            e.target.src = this.ImgAvatar;
        },
        datetimeFormat(datetime) {
            // return moment(datetime, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY HH:mm:ssZ');
            return datetime;
        },
        hideAllInfoWindows(history=false) {
            if (history === true) {
                for (var i=0; i < this.infoWindowsHistory.length; i++) {
                    this.infoWindowsHistory[i].close();
                }
            } else {
                for (var j=0; j < this.infoWindows.length; j++) {
                    this.infoWindows[j].close();
                }
            }
        },

        mapBounds(point=null) {
            var self = this;

            self.$nextTick(() => {
                self.$refs.mapRefDetail.$mapPromise.then((map) => {
                    const google = self.google;
                    var bounds = new google.maps.LatLngBounds();

                    if (point !== null) {
                        bounds.extend(new google.maps.LatLng(parseFloat(point.north_east.lat), parseFloat(point.north_east.lng)));
                        bounds.extend(new google.maps.LatLng(parseFloat(point.south_west.lat), parseFloat(point.south_west.lng)));
                    } else {
                        bounds.extend(new google.maps.LatLng(self.bounds.north, self.bounds.east));
                        bounds.extend(new google.maps.LatLng(self.bounds.south, self.bounds.west));
                    }

                    // add markers to bounds
                    if (self.markers.length > 0) {
                        for (var i = 0; i < self.markers.length; i++) {
                            bounds.extend(new google.maps.LatLng(self.markers[i].position.lat(), self.markers[i].position.lng()));
                        }
                    }

                    // Apply fitBounds
                    map.fitBounds(bounds); 
                });
            });
        },

        dateFormat (classes, date) {
            if (!classes.disabled) {
                classes.disabled = moment(date.getTime()).subtract({ hours: 12})._d > new Date();
            }
            return classes;
        },

        getDataCsv(data) {
            this.csvData = data.map((item) => {
                return {
                    target_name  : item.target_name?item.target_name:'-',
                    msisdn       : item.msisdn?item.msisdn.toString():'-',
                    lac          : item.lac?item.lac:'-',
                    ci           : item.ci?item.ci:'-',
                    coordinates  : item.location[0].lat?item.location[0].lng+','+item.location[0].lat:'-',
                    datetime     : item.created_at?item.created_at.slice(0, -4):'-',
                };
            })
        },

        pageChange(page) {
            this.page = page;
        },
        rangeChange(start, end) {
            this.start = start;
            this.end = end;
        },
        sizeChange() {
            this.page = 0;
        },

        pagination() {
            // update fontawesome to iconfont
            var paginationButtonPreview = document.getElementsByClassName("fa-angle-left");

            if (paginationButtonPreview[0]) {
                var classListPrev = paginationButtonPreview[0].classList;

                if (Object.values(classListPrev).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonPreview[0].classList.add('iconfont');
                    paginationButtonPreview[0].classList.add('iconleft');
                    paginationButtonPreview[0].style.fontSize = '12px';
                }
            }

            var paginationButtonNext = document.getElementsByClassName("fa-angle-right");

            if (paginationButtonNext[0]) {
                var classListNext = paginationButtonNext[0].classList;

                if (Object.values(classListNext).filter(key => ['iconfont'].includes(key)).length === 0) {
                    paginationButtonNext[0].classList.add('iconfont');
                    paginationButtonNext[0].classList.add('iconright');
                    paginationButtonNext[0].style.fontSize = '12px';
                }
            }
        },

        async pickerUpdate(e) {
            let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;

            if (ranges > this.$store.getters['config/getValidateRangeTV']) {
                // let def = this.$store.getters['config/getDateRangeByRoute'](this.route_name)
                // const default_date = this.$store.getters['config/getDefaultDate'](def);
                
                this.dateRange.startDate = moment(this.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(this.to_date, 'YYYY-MM-DD').toDate();
                
                // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');

                this.$swal.fire({
                    icon: 'error',
                    title: 'Date range is not more than ' + this.$store.getters['config/getValidateRangeTV'] + ' days!',
                    text: 'Your date range is ' + ranges + ' days.',
                })

            } else {
                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                this.getMonthInfo()
            }
        },

        setData(data) {
            // console.log('=====setData', data)
            this.optTarget= []
            for (var i = 0; i < data.target.length; i++) {
                var tmpTarget = {
                    'id': data.target[i].id,
                    'name': data.target[i].target_name,
                    'msisdn': data.target[i].msisdn,
                    'label': data.target[i].label,
                }
                this.optTarget.push(tmpTarget)
            }

            try {
                if (!this.detailData) {                    
                    this.setDate(data)
                    this.getMonthInfo()
                }
            } catch (error) {
                console.log('error', error)
            }
            
        },
        setDate(data) {
            if (data.from_date && data.to_date) {
                this.dateRange.startDate = moment(data.from_date, 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(data.to_date, 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            } else {
                const default_date = this.$store.getters['config/getDefaultDate'](14);

                this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD').toDate();
                this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD').toDate();

                this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            }
        },

        filterList(data) {
            this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
    
            this.detailData = true

            var targetId = []
            var msisdn = []

            if(this.pickedTarget.length == 0){
                for (var i = 0; i < data.length; i++) {
                    msisdn.push(data[i].msisdn[0])
                }                
            } else {
                msisdn = this.pickedTarget
            }
            for(var i = 0; i < msisdn.length; i++){
                targetId.push(this.getTargetId(msisdn[i]))
            }
            let fromDate = this.from_date
            let toDate = this.to_date
            if (Object.keys(this.selectedTimeByDate).length > 0) {
                let tmpFromDate = []
                let tmpToDate = []
                for (var key of Object.keys(this.selectedTimeByDate)) {
                    console.log(key + " -> " + this.selectedTimeByDate[key])
                    this.selectedTimeByDate[key].forEach(element => {
                        const tmpTime = element.split("-")
                        console.log(element + " -> " + element.split("-"))
                        tmpFromDate.push(key +' '+tmpTime[0])
                        tmpToDate.push(key +' '+tmpTime[1])
                    });
                }
                fromDate = JSON.stringify(tmpFromDate)
                toDate = JSON.stringify(tmpToDate)
            } 

            this.$store.dispatch('territoryVirtual/getFullDetailMapData', [this.id, targetId, msisdn, fromDate, toDate, this.point, this.optionFilter])
            .then((resp) => {
                if (resp === 'success') {
                    console.log(resp)                    
                } else {
                    this.error();
                }
            });   
        },  

        getMarkerOsmIcon(location){
            let iconColor = '#ffffff'
            if (location.iconColor){
                iconColor = location.iconColor
            }
            let stroke_width = '1.4';
            let stroke = 'black';
            // let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="black" stroke-width="3"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="black" stroke-width="3" stroke-linejoin="round"/></svg>'
            let mySvgString = '<svg width="64" height="64" viewBox="0 0 64 64" fill="<color-icon>" xmlns="http://www.w3.org/2000/svg"><path d="M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'"/><path d="M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z" fill="<color-icon>" stroke="'+ stroke +'" stroke-width="'+ stroke_width +'" stroke-linejoin="round"/></svg>'
            // let myIconUrl = encodeURI("data:image/svg+xml," + mySvgString).replace('<color-icon>',iconColor).replace('#','%23');
            // let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replaceAll('<color-icon>',iconColor)).replaceAll('#','%23');
            let myIconUrl = encodeURI("data:image/svg+xml;utf8," + mySvgString.replace(/<color-icon>/g,iconColor)).replace(/#/g,'%23');

            return L.icon({
                iconUrl: myIconUrl,
                // iconSize: 40
                iconSize: 50,
                iconAnchor: [24.5, 49],
                popupAnchor: [0, -48],
            });
        },
        async startMap() {
            switch(this.vendorMap) {
                case 'osm':
                    await this.startMapOSM();
                    break;
                default:
                    await this.startMapGMAP();
                    break;
            }
        },

        handleTabChange(tabIndex, newTab, oldTab) {
            if (this.status_detail_map_data !== 'loading') {
                var tab = 'startMap'+newTab.title
                tab = tab.replace(/\s/g, '');
                // console.log(tab)
                EventEmit.$emit(tab)
            }
        },

        async startMapOSM() {
            var self = this;
            // var mapsLatLng = [];
            // self.locations.territory = [];
            // self.locations.territory = [];
            this.locations = [];
            this.locations_cluster = [];


            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };
            this.mapLoaded = true;
            
            // check ini
            let check_coordinates = [];
            let check_coordinates_cluster = [];
            let check_coordinates_duplicate = [];
            let locations = [];
            let locations_cluster = [];

            await self.$nextTick(() => {
                let map = self.$refs.mapRefOSM;
                if (map){
                    map = map.mapObject
                    if (map){
                        
                        if (self.detail_map_data.point && self.detail_map_data.point[0]) {
                            // this.rectLoaded = true;
                            self.rectangleOSM.bounds = [[self.detail_map_data.point[0].north_east.lat,self.detail_map_data.point[0].north_east.lng],[self.detail_map_data.point[0].south_west.lat, self.detail_map_data.point[0].south_west.lng]]
                            map.fitBounds(L.latLngBounds(self.rectangleOSM.bounds))
                        }

                        if (self.detail_map_data.target_list && self.detail_map_data.target_list.length > 0 && this.detailData) {
                            console.log('=====masuk 1')

                            for (var i = 0; i < self.detail_map_data.target_list.length; i++) {
                                if (Object.keys(self.detail_map_data.target_list[i]).length > 0) {

                                    var dtm = (self.detail_map_data.target_list[i].created_at ? self.detail_map_data.target_list[i].created_at.substring(0, 19).replace('T',' ') : '-');
                                    
                                    var contentString = '<div id="content">'+
                                            '<div id="bodyContent">'+
                                            '<table>'+
                                                '<tr>'+
                                                '<td class="pr-1 cell-info-window whitespace-no-wrap">Target</td>'+
                                                '<td class="pr-1 cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.detail_map_data.target_list[i].target_name+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.detail_map_data.target_list[i].msisdn+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+dtm+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.detail_map_data.target_list[i].location[0].lat+','+self.detail_map_data.target_list[i].location[0].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                                '</td>'+
                                                '</tr>'+
                                            '</table>'+
                                            '</div>'+
                                            '</div>';

                                    let centerTmp = [self.detail_map_data.target_list[i].location[0].lat, self.detail_map_data.target_list[i].location[0].lng]
                                    let labelColor = self.detail_map_data.target_list[i].label
                                    // self.locations.territory.push({name:contentString,position:centerTmp, iconColor:labelColor})
                                    let tmpPhoto = ''
                                    try {
                                        tmpPhoto = self.detail_map_data.target_list[i].photo
                                    } catch (error) {
                                        console.log(error)
                                    }

                                    // check ini
                                    if (check_coordinates.includes(centerTmp.toString())) {
                                        if (check_coordinates_duplicate.includes(centerTmp.toString()) === false) {
                                            check_coordinates_duplicate .push(centerTmp.toString());
                                        }
                                        check_coordinates_cluster.push(centerTmp.toString());

                                        locations_cluster.push({
                                            index: i+'_cluster',
                                            name:contentString,position:centerTmp, 
                                            iconColor:labelColor,
                                            photo: tmpPhoto,
                                        });
                                    } else {
                                        check_coordinates.push(centerTmp.toString());

                                        locations.push({
                                            index: i,
                                            name:contentString,position:centerTmp,
                                            iconColor:labelColor,
                                            photo: tmpPhoto,
                                        });
                                        // self.locations.push({name:contentString,position:centerTmp, iconColor:labelColor});
                                    }

                                }
                            }
                            // check ini
                            if (check_coordinates.length > 0 || check_coordinates_cluster.length > 0) {
                                const delete_location_index = [];

                                for (var i = 0; i < check_coordinates.length; i++) {
                                    for (let j = 0; j < check_coordinates_duplicate.length; j++) {
                                        if (check_coordinates[i] === check_coordinates_duplicate[j]) {
                                            locations[i].index = locations[i].index+'_cluster'
                                            locations_cluster.push(locations[i]);
                                            delete_location_index.push(i);
                                        }
                                    }
                                }

                                if (delete_location_index.length > 0) {
                                    // for (var i = 0; i < delete_location_index.length; i++) {
                                    //     locations.splice(delete_location_index[i], 1);
                                    // }

                                    for (var i = delete_location_index.length - 1; i >= 0; i--) {
                                        // console.log(delete_location_index[i])
                                        locations.splice(delete_location_index[i], 1);
                                    }
                                }
                                self.locations = locations;

                                locations_cluster = locations_cluster.reduce((r, a) => {
                                    // console.log("a", a);
                                    // console.log('r', r);
                                    r[a.position] = [...r[a.position] || [], a];
                                    return r;
                                }, {});

                                // console.log(locations_cluster)
                                self.locations_cluster = locations_cluster;
                            }
                        } 
                        
                        else if(self.detail_map_data.target_list && self.detail_map_data.target_list.length == 0 && this.detailData){
                            self.locations.territory = [];
                        }
                        
                        else if (self.detail_map_data.target && self.detail_map_data.target.length > 0) {
                            console.log('=====masuk 2')

                            for (var i = 0; i < self.detail_map_data.target.length; i++) {
                                if (Object.keys(self.detail_map_data.target[i]).length > 0) {

                                    var dtm = (self.detail_map_data.target[i].created_at ? self.detail_map_data.target[i].created_at.substring(0, 19).replace('T',' ') : '-');
                                    
                                    var contentString = '<div id="content">'+
                                            '<div id="bodyContent">'+
                                            '<table>'+
                                                '<tr>'+
                                                '<td class="pr-1 cell-info-window whitespace-no-wrap">Target</td>'+
                                                '<td class="pr-1 cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.detail_map_data.target[i].target_name+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+self.detail_map_data.target[i].msisdn+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window">'+dtm+'</td>'+
                                                '</tr>'+
                                                '<tr>'+
                                                '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                                '<td class="cell-info-window">:</td>'+
                                                '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.detail_map_data.target[i].location[0].lat+','+self.detail_map_data.target[i].location[0].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                                '</td>'+
                                                '</tr>'+
                                            '</table>'+
                                            '</div>'+
                                            '</div>';

                                    let centerTmp = [self.detail_map_data.target[i].location[0].lat, self.detail_map_data.target[i].location[0].lng]
                                    let labelColor = self.detail_map_data.target[i].label
                                    // self.locations.territory.push({name:contentString,position:centerTmp, iconColor:labelColor})
                                    let tmpPhoto = ''
                                    try {
                                        tmpPhoto = self.detail_map_data.target_list[i].photo
                                    } catch (error) {
                                        console.log(error)
                                    }
                                    console.log('=====masuk 2 index')
                                    console.log(i)
                                    // check ini
                                    if (check_coordinates.includes(centerTmp.toString())) {
                                        if (check_coordinates_duplicate.includes(centerTmp.toString()) === false) {
                                            check_coordinates_duplicate .push(centerTmp.toString());
                                        }
                                        check_coordinates_cluster.push(centerTmp.toString());

                                        locations_cluster.push({
                                            index: i+'_cluster',
                                            name:contentString,position:centerTmp, 
                                            iconColor:labelColor,
                                            photo: tmpPhoto,
                                        });
                                    } else {
                                        check_coordinates.push(centerTmp.toString());

                                        locations.push({
                                            index: i,
                                            name:contentString,position:centerTmp,
                                            iconColor:labelColor,
                                            photo: tmpPhoto,
                                        });
                                        // self.locations.push({name:contentString,position:centerTmp, iconColor:labelColor});
                                    }

                                }

                            }
                            // check ini
                            if (check_coordinates.length > 0 || check_coordinates_cluster.length > 0) {
                                const delete_location_index = [];

                                for (var i = 0; i < check_coordinates.length; i++) {
                                    for (let j = 0; j < check_coordinates_duplicate.length; j++) {
                                        if (check_coordinates[i] === check_coordinates_duplicate[j]) {
                                            locations[i].index = locations[i].index+'_cluster'
                                            locations_cluster.push(locations[i]);
                                            delete_location_index.push(i);
                                        }
                                    }
                                }

                                if (delete_location_index.length > 0) {
                                    // for (var i = 0; i < delete_location_index.length; i++) {
                                    //     locations.splice(delete_location_index[i], 1);
                                    // }

                                    for (var i = delete_location_index.length - 1; i >= 0; i--) {
                                        // console.log(delete_location_index[i])
                                        locations.splice(delete_location_index[i], 1);
                                    }
                                }
                                self.locations = locations;

                                locations_cluster = locations_cluster.reduce((r, a) => {
                                    // console.log("a", a);
                                    // console.log('r', r);
                                    r[a.position] = [...r[a.position] || [], a];
                                    return r;
                                }, {});

                                // console.log(locations_cluster)
                                self.locations_cluster = locations_cluster;
                            }
                        } 
                    }
                }
            });
        },

        async startMapGMAP() {
            var self = this;
            // var mapsLatLng = [];

            self.infoWindows = [];
            self.markers = [];
            self.mapsLatLng = [];
            // self.polyline = null;

            // monas, jakarta
            this.center = {
                lat: -6.1763562,
                lng: 106.8227796,
            };

            this.mapLoaded = true;

            await self.$nextTick(() => {
                self.$refs.mapRefDetail.$mapPromise.then((map) => {
                    const google = self.google;

                    if (self.detail_map_data.point && self.detail_map_data.point[0]) {

                        // this.rectLoaded = true;
                        let bounds_rectangle = {
                            north: parseFloat(self.detail_map_data.point[0].north_east.lat),
                            east: parseFloat(self.detail_map_data.point[0].north_east.lng),
                            south: parseFloat(self.detail_map_data.point[0].south_west.lat),
                            west: parseFloat(self.detail_map_data.point[0].south_west.lng),
                        };

                        self.rectangle = new google.maps.Rectangle({
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            // fillColor: "#FF0000",
                            fillColor: "#FFF",
                            fillOpacity: 0.35,
                            // bounds: bounds,
                            bounds: bounds_rectangle,
                            // editable: true,
                            // draggable: true,
                        });

                        self.rectangle.setMap(map);
                        self.mapBounds(self.detail_map_data.point[0]);
                    }

                    // map.center = new google.maps.LatLng(self.center);

                    if (self.detail_map_data.target_list && self.detail_map_data.target_list.length > 0) {
                        self.gInfoWIndow = new google.maps.InfoWindow();
                        const infoWindow = self.gInfoWIndow;
                        const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier')

                        const oms = new OverlappingMarkerSpiderfier(map, {
                            markersWontMove: true,
                            markersWontHide: true,
                            keepSpiderfied: true,
                            // circleFootSeparation:69,
                            nudgeStackedMarkers:false,
                        });

                        google.maps.event.addListener(map, "click", function(event) {
                            infoWindow.close();
                        });

                        oms.addListener('click', function(marker) {
                            self.currentClickMarker = marker
                            // infoWindow.setContent(marker.desc);
                            // infoWindow.open(map, marker);
                        });

                        oms.addListener('unspiderfy', function(markers) {
                            // console.log('unspiderfy markers')
                            var spidered = markers

                            for (var i = 0; i < spidered.length; i ++) {
                                let idMark = spidered[i].mark_id
                                let index = self.spideredMarker.indexOf(idMark);
                                if (index > -1) { 
                                  self.spideredMarker.splice(index, 1); 
                                }
                                
                                let testIcon = spidered[i].cust_icon.icon
                                let testIconMerge = spidered[i].cust_icon.iconMerge
                                spidered[i].setIcon(testIconMerge);
                            };
                            self.currentClickMarker = null
                        });

                        oms.addListener('spiderfy', function(markers) {
                            // console.log('spiderfy markers')
                            // console.log(markers)
                            var spidered = markers;
                            var firstData = null;

                            for (var i = 0; i < spidered.length; i ++) {

                                let testIcon = spidered[i].cust_icon.icon
                                let idMark = spidered[i].mark_id
                                let testIconMerge = spidered[i].cust_icon.iconMerge
                                spidered[i].setIcon(testIcon);
                                self.spideredMarker.push(idMark)
                                // firstData = spidered[i]
                            };
                            if (firstData)
                                self.currentClickMarker = firstData
                        });

                        google.maps.event.addListener(map, 'zoom_changed', function() {        

                            infoWindow.close();
                            google.maps.event.addListenerOnce(map, 'idle', function() {
                                // console.log('self.checkMarker++++++++++++++++++++++++++++++++++++++:BEGIN')
                                // if (self.checkMarker){
                                var spidered = oms.markersNearAnyOtherMarker();
                                spidered = spidered.filter(function(v){
                                    return !self.spideredMarker.includes(v.mark_id);
                                });

                                let tmp = null
                                
                                if (spidered.length){
                                    tmp = self.currentClickMarker
                                    self.currentClickMarker = null
                                    // console.log('call markersNearAnyOtherMarker-------------------------------------------')
                                }
                                
                                for (var i = 0; i < spidered.length; i ++) {
                                    let testIcon = spidered[i].cust_icon.icon
                                    let testIconMerge = spidered[i].cust_icon.iconMerge
                                    spidered[i].setIcon(testIconMerge);
                                };

                                if (spidered.length){
                                    self.currentClickMarker = tmp
                                    window.setInterval(() => {
                                        self.currentClickMarker = null;
                                    }, 100);

                                }
                                // console.log('self.checkMarker++++++++++++++++++++++++++++++++++++++:END')

                            });

                        }); 

                        let testMapData = self.detail_map_data.target_list
                        // console.log({testMapData})

                        let i;
                        var iconMerge = {
                                    url: '/images/green-people.svg',
                                    scaledSize: new google.maps.Size(50, 50)  // makes SVG icons work in IE
                              }

                        for (i = 0; i < self.detail_map_data.target_list.length; i++) {

                            if (Object.keys(self.detail_map_data.target_list[i]).length > 0) {
                                var icon = {
                                    path: 'M31.5766 15.7225C35.366 15.7225 38.4379 12.6506 38.4379 8.86127C38.4379 5.07189 35.366 2 31.5766 2C27.7872 2 24.7153 5.07189 24.7153 8.86127C24.7153 12.6506 27.7872 15.7225 31.5766 15.7225Z M18 26.8175V41.4161C18 43.1679 19.4599 44.4817 21.0657 44.4817H23.9854L25.5911 59.5183C25.7372 60.9782 26.905 62 28.3649 62H34.6423C36.1022 62 37.2701 60.978 37.4161 59.5183L39.0219 44.4817H41.9416C43.6934 44.4817 45.0073 43.1679 45.0073 41.4161V26.8175C45.1533 14.9926 18 14.9926 18 26.8175Z',
                                    fillColor: self.detail_map_data.target_list[i].label,
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: '#ffffff',
                                    scale: 0.75,
                                    anchor: new google.maps.Point(29,60),
                                }

                                const shape = {
                                    coords: [10, 10, 10],
                                    type: "circle",
                                };
                                
                                if (self.detail_map_data.target_list[i].photo && self.detail_map_data.target_list[i].photo.length>0){
                                    icon = { 
                                        url: self.detail_map_data.target_list[i].photo,
                                        scaledSize: new google.maps.Size(40, 40),
                                    }
                                }

                                var marker = new google.maps.Marker({
                                    position: new google.maps.LatLng(parseFloat(self.detail_map_data.target_list[i].location[0].lat), parseFloat(self.detail_map_data.target_list[i].location[0].lng)),
                                    map: map,
                                    icon: icon,
                                    // draggable: true
                                });

                                marker.mark_id=i+1;
                                marker.cust_icon = {
                                            icon: icon,
                                            iconMerge: iconMerge,
                                            // iconCheck: OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE
                                        };

                                // google.maps.event.addListener(marker, 'spider_format', function(status) {
                                //     if (status == OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE){
                                //       marker.setIcon({
                                //         path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
                                //         scaledSize: new google.maps.Size(23, 32)  // makes SVG icons work in IE
                                //       });
                                //     }
                                // });


                                var dtm = (self.detail_map_data.target_list[i].created_at ? self.detail_map_data.target_list[i].created_at.substring(0, 19).replace('T',' ') : '-');
                                
                                var contentString = '<div id="content">'+
                                        '<div id="bodyContent">'+
                                        '<table>'+
                                            '<tr>'+
                                            '<td class="pr-1 cell-info-window whitespace-no-wrap">Target</td>'+
                                            '<td class="pr-1 cell-info-window">:</td>'+
                                            '<td class="cell-info-window">'+self.detail_map_data.target_list[i].target_name+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                            '<td class="cell-info-window whitespace-no-wrap">MSISDN</td>'+
                                            '<td class="cell-info-window">:</td>'+
                                            '<td class="cell-info-window">'+self.detail_map_data.target_list[i].msisdn+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                            '<td class="cell-info-window whitespace-no-wrap">Datetime</td>'+
                                            '<td class="cell-info-window">:</td>'+
                                            '<td class="cell-info-window">'+dtm+'</td>'+
                                            '</tr>'+
                                            '<tr>'+
                                            '<td class="cell-info-window whitespace-no-wrap">Link Location</td>'+
                                            '<td class="cell-info-window">:</td>'+
                                            '<td class="cell-info-window"><a target="_blank" href="https://www.google.com/maps?q='+self.detail_map_data.target_list[i].location[0].lat+','+self.detail_map_data.target_list[i].location[0].lng+'" style="color: #43ea92;">'+'Google Maps</a> '+
                                            '</td>'+
                                            '</tr>'+
                                        '</table>'+
                                        '</div>'+
                                        '</div>';

                                // var infowindow = new google.maps.InfoWindow({
                                //     content: contentString,
                                //     maxWidth: 400
                                // });
                                
                                // self.infoWindows.push(infowindow);


                                // google.maps.event.addListener(marker, 'click', function() {
                                //     self.hideAllInfoWindows();
                                //     infowindow.open(map, marker);
                                //     map.panTo(this.getPosition());
                                //     map.setZoom(17);
                                // });

                                // osm
                                marker.desc=contentString;
                                
                                self.markers.push(marker);
                                
                                oms.addMarker(marker);

                                // google.maps.event.addListener(
                                //     marker, 
                                //     // 'click', 
                                //     'spider_click', 
                                //     (
                                //         function() {
                                //             infoWindow.setContent(contentString);
                                //             infoWindow.open(map, marker);
                                //         }
                                //     )
                                //     (
                                //         marker, 
                                //         i
                                //     )
                                // );
                            }

                        }
                        const renderer = {
                            render({ count, position }, stats) {
                                // change color if this cluster has more markers than the mean cluster
                                // const color = count > Math.max(10, stats.clusters.markers.mean) ? "#ff0000" : "#0000ff";
                                const color = count > Math.max(10, stats.clusters.markers.mean) ? "#43EA92" : "#43EA92";
                                // create svg url with fill color
                                const svg = window.btoa(`
                                <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                                    <circle cx="120" cy="120" opacity=".6" r="70" />
                                    <circle cx="120" cy="120" opacity=".3" r="90" />
                                    <circle cx="120" cy="120" opacity=".2" r="110" />
                                </svg>`);
                                // create marker using svg icon
                                return new google.maps.Marker({
                                    position,
                                    icon: {
                                        url: `data:image/svg+xml;base64,${svg}`,
                                        scaledSize: new google.maps.Size(45, 45),
                                    },
                                    label: {
                                        text: String(count),
                                        // color: "rgba(255,255,255,0.9)",
                                        color: "#101220",
                                        fontSize: "12px",
                                    },
                                    title: `Cluster of ${count} markers`,
                                    // adjust zIndex to be above other markers
                                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                                });
                            }
                        }
                        new MarkerClusterer({
                            map: map,
                            markers: self.markers,
                            renderer,
                            maxZoom: 6,
                        });

                    }

                    // if (self.mapsLatLng.length > 0) {
                    //     self.polyline = new google.maps.Polyline({
                    //       path: self.mapsLatLng,
                    //       strokeColor: '#FF0000',
                    //       strokeOpacity:0.8,
                    //       strokeWeight:1.5
                    //     });

                    //     self.polyline.setMap(map);
                    // }

                });
            });
        },
        
        async getData() {
            await this.$store.commit('territoryVirtual/setDetailMapData', {});

            if (this.route_name === 'territory-virtual-detail') {

                this.$store.dispatch('territoryVirtual/getFullDetailMapData', [this.id])
                .then((resp) => {
                    if (resp === 'success') {
                        console.log(resp)
                    } else {
                        this.error();
                    }
                });

            }
        },

        showLoading(show=true) {
            if (show) {
                this.$swal.fire({
                    title: '',
                    html: '<div class="save_loading"><svg viewBox="0 0 140 140" width="140" height="140"><g class="outline"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="rgba(0,0,0,0.1)" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round"></path></g><g class="circle"><path d="m 70 28 a 1 1 0 0 0 0 84 a 1 1 0 0 0 0 -84" stroke="#71BBFF" stroke-width="4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-dashoffset="200" stroke-dasharray="300"></path></g></svg></div><div><h4>Loading...</h4></div>',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });

            } else {
                this.$swal.close();
            }
        },
        error(message=null, data=null) {
            let errors = '';

            if (data !== null) {
                Object.keys(data).forEach(function (item) {
                    errors += data[item] + '<br><br>';
                    errors += '<div class="swal2-html-container" style="display: block;">' + data[item] + '<div>';
                });
            }

            this.$swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: (message!==null ? message : 'Something wrong.'),
                html: errors,
                // timer: 3000
            });
        },

        toggleLandscape() {
            this.landscapeMap = !this.landscapeMap
        },
        toggleFullScreen() {
            this.mapFullScreen = !this.mapFullScreen
            const el = document.body

            if(this.mapFullScreen === true) {
                console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },
        toggleAdvanceFilter(action=null) {
            this.openAdvanceFilter = !this.openAdvanceFilter
            if (action =='reset') {
                this.selectedTimeAll = []
                this.selectedTimeByDate = {}
            }
            const el = document.body

            if(this.openAdvanceFilter === true) {
                console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },

        toggleFilterTarget() {
            this.openFilterTarget = !this.openFilterTarget
            const el = document.body

            if(this.openFilterTarget === true) {
                console.log('body freezee')
                el.classList.add('body-freeze')
            }else {
                console.log('body oveflow')
                el.classList.remove('body-freeze')
            }
        },
        resetTarget(){
            // var allTarget = this.detail_map_data.target.map(function(o) {
            //     return o.msisdn.toString()
            // });
            // this.pickedTarget = allTarget
            // console.log(this.pickedTarget)
            this.pickedTarget = []
            this.btnSelectedTarget = []
            this.toggleFilterTarget()        

        },
        selectTarget(targetValue, index) {

            if(!this.btnSelectedTarget.includes(index)){
                this.btnSelectedTarget.push(index);
            } else {
                this.btnSelectedTarget.splice(this.btnSelectedTarget.indexOf(index), 1);
            }    

            if(!this.pickedTarget.includes(targetValue)) {
                this.pickedTarget.push(targetValue)
            } else {
                const index = this.pickedTarget.indexOf(targetValue)
                if (index !== -1) {
                    this.pickedTarget.splice(index, 1);
                }
            }
            console.log(this.pickedTarget)
        },
    },    

    filters: {
        date: function (date) {
            return moment(date).format('YYYY-MM-DD');
        }
    },
    async mounted() {
        try {
            if (this.$route.params.data) {
                let decrypt_data = JSON.parse(decrypt(this.$route.params.data));
                // console.log('=====decrypt_data')
                // console.log(decrypt_data)
                this.setDate(decrypt_data)
                this.name = decrypt_data.name
            }
        } catch (error) {
            console.log('error set data mounted')
            console.log(error)
        }
        this.getData();
        // const default_date = this.$store.getters['config/getDefaultDate'](14);

        // this.dateRange.startDate = moment(default_date.from_date + ' 00:00', 'YYYY-MM-DD').toDate();
        // this.dateRange.endDate = moment(default_date.to_date + ' 23:59', 'YYYY-MM-DD').toDate();

        // this.from_date = moment(this.dateRange.startDate).format('YYYY-MM-DD');
        // this.to_date = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        // this.getMonthInfo()        
        
        // await this.startMap();

    },
}
</script>

<style lang="sass">
.info-target-area
    @apply flex gap-8 flex-row
    &__map
        flex: 0 0 calc( 100% / (12/7))
        position: relative
        
    &__table
        flex: 0 0 calc( 100% / (12/5))

    &--column
        @apply flex-col
        .info-target-area__map,
        .info-target-area__table
            flex: 0 0 100%

.box-map-territory
    @apply w-full  
    background-color: black
    position: sticky
    top: 12px
    &--full-screen
        @apply fixed inset-0 w-full h-full
        z-index: 99999999
        .box-map-territory__frame
            height: 100%
        
        .map-settings
            right: 12px

    .map-settings
        @apply absolute flex items-center gap-1
        top: 4px
        right: 4px
        z-index: 1000
        button
            width: 32px
            height: 32px
            border-radius: 4px
            background-color: $color-secondary-700
            display: flex
            align-items: center
            justify-content: center
            svg
                width: 20px
        
    &__search
        width: 100%
        max-width: 400px
        position: absolute
        z-index: 9
        top: 16px
        left: 50%
        margin-left: -200px
    &__frame
        width: 100%
        height: 550px
        iframe
            @apply absolute inset-0 w-full h-full


.section-advance-filter
    @apply fixed inset-0 w-full h-screen border border-gray-900 bg-black mb-8 p-4 flex flex-col
    z-index: 1001
    &__header
        @apply flex pb-4
    &__body
        @apply flex-grow overflow-auto pr-4 flex flex-col gap-4

.btn-item
    @apply  flex flex-col text-sm border p-2 border-gray-800 gap-1
    &:hover
        @apply border-green-800
    &--selected
        @apply bg-green-500 text-black
        .time-default
            @apply block
        .time-suggestion
            @apply hidden
        &:hover
            .time-default
                @apply hidden
            .time-suggestion
                @apply block

.modal-date-time
    @apply fixed inset-0 w-full h-screen border border-gray-900 mb-8 p-4 flex items-center justify-center
    background-color: rgba(0,0,0, 0.8)
    z-index: 1001

.section-date-time
    @apply w-full flex flex-col gap-6 p-8
    background-color: $color-secondary-900
    max-width: 720px
    &__body
        @apply grid grid-cols-6 gap-2
        button
            @apply flex items-center justify-center w-full
    &__header,
    &__footer
        @apply flex items-center justify-between

body
    &.body-freeze
        overflow: hidden
        .global-header__account
            z-index: 0
</style>
